body {
  background: #f5f5f5;
}
.bg-body {
  background: #f5f5f5 !important;
}
.text-primary {
  color: #00BD9F !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #00715f !important;
}
.bg-primary {
  background-color: #00BD9F !important;
}
a.bg-primary:hover, a.bg-primary:focus {
  background-color: #00b497 !important;
}
.bg-primary-dark {
  background-color: #00aa8f !important;
}
a.bg-primary-dark:hover, a.bg-primary-dark:focus {
  background-color: #00a288 !important;
}
.bg-primary-darker {
  background-color: #00a187 !important;
}
a.bg-primary-darker:hover, a.bg-primary-darker:focus {
  background-color: #009980 !important;
}
html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary {
  border-color: #00BD9F !important;
}
.badge-primary {
  background-color: #00BD9F;
  color: #fff;
}
.badge-primary[href]:hover, .badge-primary[href]:focus {
  background-color: #00b497;
  color: #fff;
  text-decoration: none;
}
.btn .badge-primary {
  background: #00BD9F !important;
  border-color: #00BD9F !important;
  color: #fff !important;
}
.badge-outline-primary {
  background-color: transparent;
  box-shadow: 0 0 0 1px #00BD9F inset;
  color: #00BD9F;
}
.badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
  color: #00BD9F;
  text-decoration: none;
}
.btn .badge-outline-primary {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #00BD9F inset !important;
  color: #00BD9F !important;
}
.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus,
.pagination li.active > a:not(.page-link),
.pagination li.active > a:not(.page-link):hover,
.pagination li.active > a:not(.page-link):focus {
  border-color: #00BD9F;
  background-color: #00BD9F;
  color: #fff;
}
.progress-bar {
  background-color: #00BD9F;
  color: #fff;
}
.list-group-item-primary {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #e6f8f5;
  color: #5c6362;
}
a.list-group-item-primary,
button.list-group-item-primary {
  color: #5c6362;
}
a.list-group-item-primary:hover, a.list-group-item-primary:focus,
  button.list-group-item-primary:hover,
  button.list-group-item-primary:focus {
    background-color: #e4f6f3;
    color: #5c6362;
}
a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    border-color: #00BD9F;
    background-color: #00BD9F;
    color: #fff;
}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  border-color: #00BD9F;
  background-color: #00BD9F;
  color: #fff;
}
.alert-primary {
  border-color: #cce6e3;
  background-color: #d9f5f1;
  color: #576260;
}
.alert-primary hr {
    border-top-color: #cce6e3;
}
.alert-primary .alert-link {
    color: #576260;
}
.alert-dark-primary {
  background-color: #00BD9F;
  color: #fff;
}
.alert-dark-primary hr {
    border-top-color: rgba(255, 255, 255, 0.2);
}
.alert-dark-primary .close,
  .alert-dark-primary .alert-link {
    color: #fff;
}
.callout-primary {
  border-color: #00BD9F !important;
  background-color: #d9f5f1;
  color: #576260;
}
.callout-primary .callout-link {
    color: #576260;
}
.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner, .ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
  background: #00BD9F;
  color: #fff;
}
.tooltip-primary.bs-tooltip-top .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-top .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #00BD9F;
}
.tooltip-primary.bs-tooltip-right .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-right .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #00BD9F;
}
.tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #00BD9F;
}
.tooltip-primary.bs-tooltip-left .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-left .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #00BD9F;
}
.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window {
  border-color: transparent;
  background: #00BD9F;
}
.popover-primary .popover-header, .popover-primary > .popover .popover-header, .ngb-popover-primary + ngb-popover-window .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #fff;
}
.popover-primary .popover-body, .popover-primary > .popover .popover-body, .ngb-popover-primary + ngb-popover-window .popover-body {
    background: transparent;
    color: #fff;
}
.popover-primary > .arrow::before, .popover-primary > .popover > .arrow::before, .ngb-popover-primary + ngb-popover-window > .arrow::before {
    border-color: transparent;
}
.popover-primary.bs-popover-top > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::after, .popover-primary > .popover.bs-popover-top > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="top"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-top > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="top"] > .arrow::after {
    border-top-color: #00BD9F;
}
.popover-primary.bs-popover-right > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::after, .popover-primary > .popover.bs-popover-right > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="right"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-right > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="right"] > .arrow::after {
    border-right-color: #00BD9F;
}
.popover-primary.bs-popover-bottom > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::after, .popover-primary > .popover.bs-popover-bottom > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="bottom"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-bottom > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    border-bottom-color: #00BD9F;
}
.popover-primary.bs-popover-left > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::after, .popover-primary > .popover.bs-popover-left > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="left"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-left > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="left"] > .arrow::after {
    border-left-color: #00BD9F;
}
.table .table-primary,
.table .table-primary > th,
.table .table-primary > td {
  border-color: rgba(0, 0, 0, 0.035) !important;
  background-color: #e6f8f5;
  color: #5c6362;
}
.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #e4f6f3;
}
.btn-primary {
  background: #00BD9F;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}
.btn-primary:hover {
    background: #0dc0a4;
    color: #fff;
}
.btn-primary:hover, .btn-primary:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}
.btn-primary .badge {
  background: #fff;
  color: #00BD9F;
}
.btn-primary .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background: #21c6ab;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}
.btn-primary.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #00BD9F;
}
.btn-primary.md-btn-flat:focus, .btn-primary.md-btn-flat.focus {
    background: rgba(0, 189, 159, 0.1);
}
.btn-primary.md-btn-flat .waves-ripple {
    background: rgba(0, 189, 159, 0.3) !important;
}
.btn-primary.md-btn-flat:active,
.btn-primary.md-btn-flat.active,
.show > .btn-primary.md-btn-flat.dropdown-toggle {
  background: rgba(0, 189, 159, 0.15);
}
.btn-primary.disabled,
.btn-primary:disabled {
  background: #00BD9F !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}
.btn-primary.md-btn-flat.disabled,
.btn-primary.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #00BD9F !important;
}
.btn-outline-primary {
  background: transparent;
  box-shadow: 0 0 0 1px #00BD9F inset;
  color: #00BD9F;
}
.btn-outline-primary:hover, .btn-outline-primary:focus {
    background: #00BD9F;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
    color: #fff;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    background: transparent !important;
    box-shadow: 0 0 0 1px #00BD9F inset !important;
    color: #00BD9F !important;
}
.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  background: #0dc0a4;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}
.btn-outline-primary .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}
.btn-outline-primary .badge {
  background: #00BD9F;
  border-color: #00BD9F;
  color: #fff;
}
.btn-outline-primary:hover .badge,
.btn-outline-primary:focus .badge,
.btn-outline-primary:active .badge,
.btn-outline-primary.active .badge,
.show > .btn-outline-primary.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #00BD9F;
}
.dropdown-item:not(.disabled).active {
  background-color: #00BD9F;
  color: #fff;
}
.dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: #00BD9F;
  color: #fff;
}
.nav-tabs .nav-link {
  background-image: linear-gradient(#00BD9F, #00BD9F);
}
.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  background-color: #00BD9F;
  color: #fff;
}
.custom-control.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #00BD9F;
}
.custom-control.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #00BD9F;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00BD9F;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.25 4.35 4.18 6.75 9.25 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.custom-control.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2300BD9F'/%3E%3C/svg%3E");
}
.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(0, 189, 159, 0.15);
}
.custom-file-input:focus ~ .custom-file-label {
  border-bottom-color: #00BD9F;
  box-shadow: 0 -1px 0 0 #00BD9F inset;
}
.form-control:focus,
.form-control.focus,
.custom-select:focus {
  border-color: #00BD9F;
  box-shadow: 0 -1px 0 0 #00BD9F inset;
}
.switcher-input:checked ~ .switcher-indicator {
  background: rgba(0, 189, 159, 0.5);
}
.switcher-input:checked ~ .switcher-indicator::after {
    background: #00BD9F;
}
.switcher-input:checked ~ .switcher-indicator::before {
    background: rgba(0, 189, 159, 0.15);
}
.navbar.bg-primary {
  background-color: #00BD9F !important;
  color: #cdf2ec;
}
.navbar.bg-primary .navbar-brand {
    color: #fff;
}
.navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus {
      color: #fff;
}
.navbar.bg-primary .navbar-nav .nav-link {
    color: #cdf2ec;
}
.navbar.bg-primary .navbar-nav .nav-link:hover, .navbar.bg-primary .navbar-nav .nav-link:focus {
      color: #fff;
}
.navbar.bg-primary .navbar-nav .nav-link.disabled {
      color: #7bddcd !important;
}
.navbar.bg-primary .navbar-nav .show > .nav-link,
  .navbar.bg-primary .navbar-nav .active > .nav-link,
  .navbar.bg-primary .navbar-nav .nav-link.show,
  .navbar.bg-primary .navbar-nav .nav-link.active {
    color: #fff;
}
.navbar.bg-primary .navbar-toggler {
    color: #cdf2ec;
    border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-primary .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-primary .navbar-text {
    color: #cdf2ec;
}
.navbar.bg-primary .navbar-text a {
      color: #fff;
}
.navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
        color: #fff;
}
.navbar.bg-primary hr {
    border-color: rgba(255, 255, 255, 0.15);
}
.sidenav.bg-primary {
  background-color: #00BD9F !important;
  color: #cdf2ec;
}
.sidenav.bg-primary .sidenav-link,
  .sidenav.bg-primary .sidenav-horizontal-prev,
  .sidenav.bg-primary .sidenav-horizontal-next {
    color: #cdf2ec;
}
.sidenav.bg-primary .sidenav-link:hover, .sidenav.bg-primary .sidenav-link:focus,
    .sidenav.bg-primary .sidenav-horizontal-prev:hover,
    .sidenav.bg-primary .sidenav-horizontal-prev:focus,
    .sidenav.bg-primary .sidenav-horizontal-next:hover,
    .sidenav.bg-primary .sidenav-horizontal-next:focus {
      color: #fff;
}
.sidenav.bg-primary .sidenav-link.active,
    .sidenav.bg-primary .sidenav-horizontal-prev.active,
    .sidenav.bg-primary .sidenav-horizontal-next.active {
      color: #fff;
}
.sidenav.bg-primary .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-primary .sidenav-horizontal-prev.disabled,
  .sidenav.bg-primary .sidenav-horizontal-next.disabled {
    color: #7bddcd !important;
}
.sidenav.bg-primary .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-primary .sidenav-item.active > .sidenav-link {
    color: #fff;
}
.sidenav.bg-primary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #00b497;
}
.sidenav.bg-primary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #00ab8f;
}
.sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #00b497;
}
.sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #cdf2ec;
}
.sidenav.bg-primary .sidenav-text {
    color: #fff;
}
.sidenav.bg-primary .sidenav-header {
    color: #9ae5d9;
}
.sidenav.bg-primary hr,
  .sidenav.bg-primary .sidenav-divider,
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.15) !important;
}
.sidenav.bg-primary .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-primary .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.15);
}
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #26bfa7;
}
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff;
}
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    box-shadow: 0 0 0 2px #00b497;
}
.sidenav.bg-primary .ps__thumb-y,
  .sidenav.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.602461) !important;
}
.footer.bg-primary {
  background-color: #00BD9F !important;
  color: #cdf2ec;
}
.footer.bg-primary .footer-link {
    color: #cdf2ec;
}
.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
      color: #fff;
}
.footer.bg-primary .footer-link.disabled {
      color: #7bddcd !important;
}
.footer.bg-primary .footer-text {
    color: #fff;
}
.footer.bg-primary .show > .footer-link,
  .footer.bg-primary .active > .footer-link,
  .footer.bg-primary .footer-link.show,
  .footer.bg-primary .footer-link.active {
    color: #fff;
}
.footer.bg-primary hr {
    border-color: rgba(255, 255, 255, 0.15);
}
.bg-primary.bs4-toast {
  color: #fff;
  background-color: rgba(0, 189, 159, 0.9) !important;
}
.bg-primary.bs4-toast .toast-header {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
}
.fill-primary {
  fill: #00BD9F;
}
.sk-primary.sk-plane,
.sk-primary .sk-chase-dot:before,
.sk-primary .sk-bounce-dot,
.sk-primary .sk-wave-rect,
.sk-primary.sk-pulse,
.sk-primary .sk-swing-dot,
.sk-primary .sk-circle-dot:before,
.sk-primary .sk-circle-fade-dot:before,
.sk-primary .sk-grid-cube,
.sk-primary .sk-fold-cube:before {
  background-color: #00BD9F;
}
.plyr[class] .plyr__control--overlaid {
  background: rgba(0, 189, 159, 0.75) !important;
  color: #fff !important;
}
.plyr[class] .plyr__control.plyr__tab-focus,
.plyr[class] .plyr__control:hover,
.plyr[class] .plyr__control[aria-expanded='true'] {
  background: #00BD9F !important;
  color: #fff !important;
}
.plyr[class] .plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']:not(.plyr__tab-focus):not(:hover)::before {
  background: #00BD9F !important;
}
.plyr[class] .plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']:not(.plyr__tab-focus):not(:hover)::after {
  background: #fff !important;
}
.plyr[class] .plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 0 rgba(0, 189, 159, 0.4) !important;
}
.plyr--full-ui[class] input[type='range'] {
  color: #00BD9F !important;
}
.plyr--full-ui[class] input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 0 rgba(0, 189, 159, 0.4) !important;
}
.plyr--full-ui[class] input[type='range'].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 0 rgba(0, 189, 159, 0.4) !important;
}
.plyr--full-ui[class] input[type='range'].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 0 rgba(0, 189, 159, 0.4) !important;
}
.multiselect__spinner:before,
.multiselect__spinner:after {
  border-top-color: #00BD9F !important;
}
.multiselect--active .multiselect__tags {
  border-color: #00BD9F !important;
  box-shadow: 0 -1px 0 0 #00BD9F inset !important;
}
.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight {
  background: #00BD9F !important;
  color: #fff !important;
}
.multiselect-primary .multiselect__tag {
  background: #00BD9F !important;
  color: #fff !important;
}
.input-tag-primary .input-tag {
  background: #00BD9F !important;
  color: #fff !important;
}
.flatpickr-calendar .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}
.flatpickr-month,
span.flatpickr-weekday {
  background: #00BD9F;
  color: #fff;
  fill: #fff;
}
.flatpickr-weekdays {
  background: #00BD9F;
}
.flatpickr-day.today {
  border-color: #00BD9F;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange {
  border-color: #d9f5f1 !important;
  background: #d9f5f1 !important;
  color: #576260 !important;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.week.selected {
  border-color: #00BD9F !important;
  background: #00BD9F !important;
  color: #fff !important;
}
.notification.vue-notification {
  background: #00BD9F;
  color: #fff;
}
.vue-form-wizard .wizard-nav > li.active .wizard-icon-circle {
  border-color: #00BD9F !important;
  color: #00BD9F;
}
.ui-product-color.active {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #00BD9F !important;
}
.bg-navbar-theme {
  background-color: #fff !important;
  color: #a3a4a6;
}
.bg-navbar-theme .navbar-brand {
    color: #616E7D;
}
.bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus {
      color: #616E7D;
}
.bg-navbar-theme .navbar-nav .nav-link {
    color: #a3a4a6;
}
.bg-navbar-theme .navbar-nav .nav-link:hover, .bg-navbar-theme .navbar-nav .nav-link:focus {
      color: #616E7D;
}
.bg-navbar-theme .navbar-nav .nav-link.disabled {
      color: #c8c8ca !important;
}
.bg-navbar-theme .navbar-nav .show > .nav-link,
  .bg-navbar-theme .navbar-nav .active > .nav-link,
  .bg-navbar-theme .navbar-nav .nav-link.show,
  .bg-navbar-theme .navbar-nav .nav-link.active {
    color: #616E7D;
}
.bg-navbar-theme .navbar-toggler {
    color: #a3a4a6;
    border-color: rgba(97, 110, 125, 0.075);
}
.bg-navbar-theme .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(24, 28, 33, 0.4)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.bg-navbar-theme .navbar-text {
    color: #a3a4a6;
}
.bg-navbar-theme .navbar-text a {
      color: #616E7D;
}
.bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
        color: #616E7D;
}
.bg-navbar-theme hr {
    border-color: rgba(97, 110, 125, 0.075);
}
.layout-navbar {
  box-shadow: 0 0 20px 0 rgba(24, 28, 33, 0.1);
}
.bg-sidenav-theme {
  background-color: #2e323a !important;
  color: #838790;
}
.bg-sidenav-theme .sidenav-link,
  .bg-sidenav-theme .sidenav-horizontal-prev,
  .bg-sidenav-theme .sidenav-horizontal-next {
    color: #838790;
}
.bg-sidenav-theme .sidenav-link:hover, .bg-sidenav-theme .sidenav-link:focus,
    .bg-sidenav-theme .sidenav-horizontal-prev:hover,
    .bg-sidenav-theme .sidenav-horizontal-prev:focus,
    .bg-sidenav-theme .sidenav-horizontal-next:hover,
    .bg-sidenav-theme .sidenav-horizontal-next:focus {
      color: #fff;
}
.bg-sidenav-theme .sidenav-link.active,
    .bg-sidenav-theme .sidenav-horizontal-prev.active,
    .bg-sidenav-theme .sidenav-horizontal-next.active {
      color: #fff;
}
.bg-sidenav-theme .sidenav-item.disabled .sidenav-link,
  .bg-sidenav-theme .sidenav-horizontal-prev.disabled,
  .bg-sidenav-theme .sidenav-horizontal-next.disabled {
    color: #61656e !important;
}
.bg-sidenav-theme .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .bg-sidenav-theme .sidenav-item.active > .sidenav-link {
    color: #fff;
}
.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #383c44;
}
.bg-sidenav-theme.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #42464d;
}
.bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #383c44;
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #838790;
}
.bg-sidenav-theme .sidenav-text {
    color: #fff;
}
.bg-sidenav-theme .sidenav-header {
    color: #6e727b;
}
.bg-sidenav-theme hr,
  .bg-sidenav-theme .sidenav-divider,
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.06) !important;
}
.bg-sidenav-theme .sidenav-inner > .sidenav-header::before,
  .bg-sidenav-theme .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.06);
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #44484f;
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff;
}
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    box-shadow: 0 0 0 2px #383c44;
}
.bg-sidenav-theme .ps__thumb-y,
  .bg-sidenav-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.477986) !important;
}
.bg-footer-theme {
  background-color: #fff !important;
  color: #a3a4a6;
}
.bg-footer-theme .footer-link {
    color: #a3a4a6;
}
.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
      color: #616E7D;
}
.bg-footer-theme .footer-link.disabled {
      color: #c8c8ca !important;
}
.bg-footer-theme .footer-text {
    color: #616E7D;
}
.bg-footer-theme .show > .footer-link,
  .bg-footer-theme .active > .footer-link,
  .bg-footer-theme .footer-link.show,
  .bg-footer-theme .footer-link.active {
    color: #616E7D;
}
.bg-footer-theme hr {
    border-color: rgba(97, 110, 125, 0.075);
}
